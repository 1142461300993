<template>
    <div>
        <CRow class="mb-2">
            <CCol md="12">
                <CCard accent-color="info">
                    <CCardHeader>
                        <h4>Administración Usuarios</h4>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol md="12">
                                <CButton size="sm" class="mb-2 mr-2 btn-class" style="width:auto" @click="nuevoUsuario = !nuevoUsuario">
                                    <CIcon name="cil-user"/> {{(nuevoUsuario)?"Cerrar Creación":"Nuevo Usuario"}}
                                </CButton>
                            </CCol>
                        </CRow>
                        <CRow v-show="nuevoUsuario">
                            <CCol sm="0" md="2"></CCol>
                            <CCol sm="12" md="8">
                                <CrearUsuario :crudPrincipal="this" />
                            </CCol>
                            <CCol sm="0" md="2"></CCol>
                        </CRow>

                        <CRow>
                            <CCol sm="12" md="8">
                                <CButton type="submit" size="sm" class="btnPrincipalVapp" v-show="userEditados.length>0" @click="editarUsuarios">
                                    <CIcon name="cil-check-circle" /> <span>Guardar Cambios</span>
                                </CButton>
                            </CCol>
                        </CRow>
                        
                        <CargandoInfo :mostrarCarga="isLoadingUsuarios" :mensajeInfo="'Obteniendo Usuarios'" />
                        <CRow v-show="!isLoadingUsuarios">
                            <CCol md="12">
                                <vs-table striped>
                                    <!--<template #header>
                                        <vs-input
                                            v-model="busquedaTrabajadores"
                                            border
                                            placeholder="Buscar"
                                        />
                                    </template>-->
                                    <template #thead>
                                        <vs-tr>
                                            <vs-th
                                                v-for="col in columns"
                                                :style="{ width: col.width }"
                                                v-bind:key="col.key"
                                                sort
                                                @click="usuarios = $vs.sortData($event, usuarios, col.key)"
                                            >
                                                {{ col.title }}
                                            </vs-th><!--Columnas Trabajadores-->
                                        </vs-tr>
                                    </template>
                                    <template #tbody>
                                        <vs-tr   
                                            :key="i" v-for="(usuario, i) in $vs.getPage($vs.getSearch(usuarios, busquedaUsuarios), page, max)"
                                            :data="usuario"
                                        >
                                            <vs-td>
                                                <div v-show="!usuario.editar">
                                                    {{usuario.userPropio?"(Yo)":""}} {{usuario.rutUsuario}}
                                                </div>
                                                
                                                <div v-show="usuario.editar">
                                                    <CInput
                                                        v-model="usuario.rutUsuario"
                                                        type="text"
                                                        horizontal
                                                        placeholder="Rut Usuario"
                                                        :is-valid="usuario.rutValidadoUser"
                                                        @blur="validarRut(usuario.rutUsuario, usuario)"
                                                    />
                                                </div>
                                            </vs-td>
                                            <vs-td>
                                                <div v-show="!usuario.editar">
                                                    {{usuario.userName}} ({{(usuario.usuarioAdmin)?"Admin":(usuario.usuarioRevision?"Revisión":"Normal")}})
                                                </div>
                                                <div v-show="usuario.editar">
                                                    <CInput
                                                        v-model="usuario.userName"
                                                        type="text"
                                                        horizontal
                                                        placeholder="Nombre Usuario"
                                                    />
                                                </div>
                                            </vs-td>

                                            <vs-td>
                                                <div v-show="!usuario.editar" style="l">
                                                    {{usuario.nombreUsuario}} {{usuario.apellidoUsuario}}
                                                </div>
                                                
                                                <div v-show="usuario.editar">
                                                    <CCol>
                                                        <CInput
                                                            v-model="usuario.nombreUsuario"
                                                            type="text"
                                                            horizontal
                                                            placeholder="Nombre"
                                                        />
                                                    </CCol>
                                                    <CCol>
                                                        <CInput
                                                            v-model="usuario.apellidoUsuario"
                                                            type="text"
                                                            horizontal
                                                            placeholder="Apellido"
                                                        />
                                                    </CCol>
                                                </div>
                                            </vs-td>
                                            <vs-td>
                                                <div v-show="!usuario.editar">
                                                    {{usuario.mailUsuario}}
                                                </div>
                                                
                                                <div v-show="usuario.editar">
                                                    <CInput
                                                        v-model="usuario.mailUsuario"
                                                        type="text"
                                                        horizontal
                                                        placeholder="Correo"
                                                        :is-valid="usuario.mailValidoUser"
                                                        @blur="validarMail(usuario)"
                                                        @keydown="validarMaximo($event,usuario.mailUsuario,'mail')"
                                                    />
                                                </div>
                                            </vs-td>
                                            <vs-td class="text-center">
                                                <div v-show="!usuario.editar">
                                                    <CIcon :style="usuario.habilitado?'color:green':'color:red'" :name="usuario.habilitado?'cil-check-circle':'cil-x-circle'"/>
                                                </div>
                                                <div v-show="usuario.editar">
                                                    <CSwitch
                                                        width="150px"
                                                        class="mx-1 align-middle mb-2" 
                                                        color="success" 
                                                        shape="pill"
                                                        variant="outline"
                                                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                        :checked="usuario.habilitado"
                                                        @update:checked="usuario.habilitado = !usuario.habilitado"
                                                    />
                                                </div>
                                            </vs-td>
                                            <vs-td>
                                                <CButton type="submit" size="sm" 
                                                    v-show="!usuario.usuarioAdmin && !usuario.editar"
                                                    color="success" 
                                                    class="btnPrincipalVapp float-left" 
                                                    @click="asignarEmpresas(usuario)">
                                                    Asignar Empresas
                                                </CButton>

                                                <div v-show="usuario.editar">
                                                    <CRow>
                                                        <CCol sm="5">
                                                            Admin
                                                        </CCol>
                                                        <CCol sm="3">
                                                            <CSwitch
                                                                width="150px"
                                                                class="mx-1 align-middle mb-2" 
                                                                color="success" 
                                                                shape="pill"
                                                                variant="outline"
                                                                v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                                :checked="usuario.usuarioAdmin"
                                                                @update:checked="usuario.usuarioAdmin = !usuario.usuarioAdmin; validarTipoUsuarioEdit('admin',usuario.usuarioAdmin,usuario)"
                                                            />
                                                        </CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol sm="5">
                                                            Normal
                                                        </CCol>
                                                        <CCol sm="3">
                                                            <CSwitch
                                                                width="150px"
                                                                class="mx-1 align-middle mb-2" 
                                                                color="success" 
                                                                shape="pill"
                                                                variant="outline"
                                                                v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                                :checked="usuario.usuarioNormal"
                                                                @update:checked="usuario.usuarioNormal = !usuario.usuarioNormal; validarTipoUsuarioEdit('normal',usuario.usuarioNormal,usuario)"
                                                            />
                                                        </CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol sm="5">
                                                            Revision
                                                        </CCol>
                                                        <CCol sm="3">
                                                            <CSwitch
                                                                width="150px"
                                                                class="mx-1 align-middle mb-2" 
                                                                color="success" 
                                                                shape="pill"
                                                                variant="outline"
                                                                v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                                :checked="usuario.usuarioRevision"
                                                                @update:checked="usuario.usuarioRevision = !usuario.usuarioRevision; validarTipoUsuarioEdit('revision',usuario.usuarioRevision, usuario)"
                                                            />
                                                        </CCol>
                                                    </CRow>
                                                </div>
                                            </vs-td>
                                            <!--<vs-td>
                                                <div v-show="!usuario.editar">
                                                    {{(usuario.usuarioAdmin)?"Admin":(usuario.usuarioRevision?"Revisión":"Normal")}}
                                                </div>

                                                <div v-show="usuario.editar">
                                                    <CRow>
                                                        <CCol sm="5">
                                                            Admin
                                                        </CCol>
                                                        <CCol sm="3">
                                                            <CSwitch
                                                                width="150px"
                                                                class="mx-1 align-middle mb-2" 
                                                                color="success" 
                                                                shape="pill"
                                                                variant="outline"
                                                                v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                                :checked="usuario.usuarioAdmin"
                                                                @update:checked="usuario.usuarioAdmin = !usuario.usuarioAdmin; validarTipoUsuarioEdit('admin',usuario.usuarioAdmin,usuario)"
                                                            />
                                                        </CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol sm="5">
                                                            Normal
                                                        </CCol>
                                                        <CCol sm="3">
                                                            <CSwitch
                                                                width="150px"
                                                                class="mx-1 align-middle mb-2" 
                                                                color="success" 
                                                                shape="pill"
                                                                variant="outline"
                                                                v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                                :checked="usuario.usuarioNormal"
                                                                @update:checked="usuario.usuarioNormal = !usuario.usuarioNormal; validarTipoUsuarioEdit('normal',usuario.usuarioNormal,usuario)"
                                                            />
                                                        </CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol sm="5">
                                                            Revision
                                                        </CCol>
                                                        <CCol sm="3">
                                                            <CSwitch
                                                                width="150px"
                                                                class="mx-1 align-middle mb-2" 
                                                                color="success" 
                                                                shape="pill"
                                                                variant="outline"
                                                                v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                                :checked="usuario.usuarioRevision"
                                                                @update:checked="usuario.usuarioRevision = !usuario.usuarioRevision; validarTipoUsuarioEdit('revision',usuario.usuarioRevision, usuario)"
                                                            />
                                                        </CCol>
                                                    </CRow>
                                                </div>
                                            </vs-td>-->
                                            

                                            <vs-td>
                                                <CButton v-show="!usuario.editar" type="submit" size="sm"
                                                    :disabled="usuario.isReseteandoPsw"
                                                    color="success" 
                                                    class="btnPrincipalVapp float-left" 
                                                    @click="resetearContraseña(usuario)">
                                                        Resetear Contraseña
                                                    <CIcon v-show="usuario.isReseteandoPsw" :content="$options.freeSet.cilLoopCircular" class="imgr" /> 
                                                </CButton>
                                            </vs-td>
                                            
                                            <vs-td class="text-center">
                                                <div v-show="!usuario.editar">
                                                    <CIcon :style="usuario.sueldoEmpresarial?'color:green':'color:red'" :name="usuario.sueldoEmpresarial?'cil-check-circle':'cil-x-circle'"/>
                                                </div>
                                                <div v-show="usuario.editar">
                                                    <CSwitch
                                                        width="150px"
                                                        class="mx-1 align-middle mb-2" 
                                                        color="success" 
                                                        shape="pill"
                                                        variant="outline"
                                                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                        :checked="usuario.sueldoEmpresarial"
                                                        @update:checked="usuario.sueldoEmpresarial = !usuario.sueldoEmpresarial"
                                                    />
                                                </div>
                                            </vs-td>

                                            <vs-td>
                                                <CButtonToolbar >
                                                    <CAlert v-show="usuario.eliminar" color="danger">
                                                        ¿Está seguro?
                                                        <CButtonGroup class="mx-1">
                                                            <CButton color="success" size="sm"
                                                                v-c-tooltip.hover="{content:'Si, Eliminar', placement:'left'}"
                                                                @click="eliminarUser(usuario)">
                                                                <CIcon name="cil-check-circle"/><!-- Si!-->
                                                            </CButton>
                                                            <CButton color="danger" size="sm"
                                                                v-c-tooltip.hover="{content:'No!', placement:'right'}"
                                                                @click="usuario.eliminar = false">
                                                                <CIcon name="cil-x-circle"/><!-- No!-->
                                                            </CButton>
                                                        </CButtonGroup>
                                                    </CAlert>
                                                    

                                                    <CButtonGroup class="mx-1" v-show="usuario.editar && !usuario.eliminar">
                                                        <CButton color="success" size="sm"
                                                            v-c-tooltip.hover="{content:'Aceptar Cambios', placement:'left'}"
                                                            :disabled="!usuario.rutValidadoUser 
                                                            || !usuario.mailValidoUser 
                                                            || !usuario.userName.length>0
                                                            || !usuario.nombreUsuario.length>0
                                                            || !usuario.apellidoUsuario.length>0"
                                                            @click="editarUsuarios(usuario)">
                                                            <CIcon name="cil-check-circle"/><!-- Aceptar-->
                                                        </CButton>
                                                        <CButton color="danger" size="sm"
                                                            v-c-tooltip.hover="{content:'Cancelar Cambios', placement:'right'}"
                                                            @click="usuario.editar=false; cancelarEdicion(usuario)">
                                                            <CIcon name="cil-x-circle"/><!-- Cancelar-->
                                                        </CButton>
                                                    </CButtonGroup>

                                                    <CButtonGroup size="sm" class="mx-1" v-show="!usuario.editar && !usuario.eliminar">
                                                        <CButton color="warning" size="sm"
                                                        v-c-tooltip.hover="{content:'Editar Usuario', placement:'left'}"
                                                        @click="usuario.editar = true; editarUser(usuario)">
                                                            <CIcon name="cil-pencil"/><!-- Editar-->
                                                        </CButton>
                                                        <CButton color="danger" 
                                                            v-c-tooltip.hover="{content:'Eliminar Usuario', placement:'right'}"
                                                            @click="usuario.eliminar = true" size="sm" ><CIcon name="cil-x-circle"/><!-- Eliminar-->
                                                        </CButton>
                                                    </CButtonGroup>
                                                    
                                                </CButtonToolbar>
                                            </vs-td>

                                            




                                        </vs-tr>

                                    </template>
                                </vs-table>
                            </CCol>
                        </CRow>
                        <CRow v-show="false">
                            <CCol md="12">
                                <a-table
                                    :columns="columns" 
                                    :data-source="usuarios"
                                    :pagination="{ pageSize: 30 }"
                                    :locale="locale"
                                    :loading="isLoadingUsuarios"
                                    class="components-table-demo-nested m-1" 
                                    size="small" >

                                    <span slot="habilitados">
                                        <div>
                                            Habilitado
                                        </div>
                                    </span>

                                    <span slot="rutUsuario" slot-scope="record">
                                        <div v-show="!record.editar">
                                            {{record.userPropio?"(Yo)":""}} {{record.rutUsuario}}
                                        </div>
                                        
                                        <div v-show="record.editar">
                                            <CInput
                                                v-model="record.rutUsuario"
                                                type="text"
                                                horizontal
                                                placeholder="Rut Usuario"
                                                :is-valid="record.rutValidadoUser"
                                                @blur="validarRut(record.rutUsuario, record)"
                                            />
                                        </div>
                                    </span>

                                    <span slot="userName"
                                        slot-scope="record">
                                        <div v-show="!record.editar">
                                            {{record.userName}} ({{(record.usuarioAdmin)?"Admin":(record.usuarioRevision?"Revisión":"Normal")}})
                                        </div>
                                        <div v-show="record.editar">
                                            <CInput
                                                v-model="record.userName"
                                                type="text"
                                                horizontal
                                                placeholder="Nombre Usuario"
                                            />
                                        </div>
                                    </span>

                                    <span slot="nombreUsuario" slot-scope="record">
                                        <div v-show="!record.editar" style="l">
                                            {{record.nombreUsuario}} {{record.apellidoUsuario}}
                                        </div>
                                        
                                        <div v-show="record.editar">
                                            <CCol>
                                                <CInput
                                                    v-model="record.nombreUsuario"
                                                    type="text"
                                                    horizontal
                                                    placeholder="Nombre"
                                                />
                                            </CCol>
                                            <CCol>
                                                <CInput
                                                    v-model="record.apellidoUsuario"
                                                    type="text"
                                                    horizontal
                                                    placeholder="Apellido"
                                                />
                                            </CCol>
                                        </div>
                                    </span>

                                    <span slot="mailUsuario"
                                    slot-scope="record">
                                        <div v-show="!record.editar">
                                            {{record.mailUsuario}}
                                        </div>
                                        
                                        <div v-show="record.editar">
                                            <CInput
                                                v-model="record.mailUsuario"
                                                type="text"
                                                horizontal
                                                placeholder="Correo"
                                                :is-valid="record.mailValidoUser"
                                                @blur="validarMail(record)"
                                                @keydown="validarMaximo($event,record.mailUsuario,'mail')"
                                            />
                                        </div>
                                    </span>

                                    <span slot="habilitado"
                                    slot-scope="record">
                                        <div v-show="!record.editar">
                                            <CIcon :style="record.habilitado?'color:green':'color:red'" :name="record.habilitado?'cil-check-circle':'cil-x-circle'"/>
                                        </div>
                                        <div v-show="record.editar">
                                            <CSwitch
                                                width="150px"
                                                class="mx-1 align-middle mb-2" 
                                                color="success" 
                                                shape="pill"
                                                variant="outline"
                                                v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                :checked="record.habilitado"
                                                @update:checked="record.habilitado = !record.habilitado"
                                            />
                                        </div>
                                    </span>

                                    <span slot="tipoUser" slot-scope="record">
                                        <div v-show="!record.editar">
                                            {{(record.usuarioAdmin)?"Admin":(record.usuarioRevision?"Revisión":"Normal")}}
                                        </div>

                                        <div v-show="record.editar">
                                            <CRow>
                                                <CCol sm="5">
                                                    Admin
                                                </CCol>
                                                <CCol sm="3">
                                                    <CSwitch
                                                        width="150px"
                                                        class="mx-1 align-middle mb-2" 
                                                        color="success" 
                                                        shape="pill"
                                                        variant="outline"
                                                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                        :checked="record.usuarioAdmin"
                                                        @update:checked="record.usuarioAdmin = !record.usuarioAdmin; validarTipoUsuarioEdit('admin',record.usuarioAdmin,record)"
                                                    />
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="5">
                                                    Normal
                                                </CCol>
                                                <CCol sm="3">
                                                    <CSwitch
                                                        width="150px"
                                                        class="mx-1 align-middle mb-2" 
                                                        color="success" 
                                                        shape="pill"
                                                        variant="outline"
                                                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                        :checked="record.usuarioNormal"
                                                        @update:checked="record.usuarioNormal = !record.usuarioNormal; validarTipoUsuarioEdit('normal',record.usuarioNormal,record)"
                                                    />
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="5">
                                                    Revision
                                                </CCol>
                                                <CCol sm="3">
                                                    <CSwitch
                                                        width="150px"
                                                        class="mx-1 align-middle mb-2" 
                                                        color="success" 
                                                        shape="pill"
                                                        variant="outline"
                                                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                        :checked="record.usuarioRevision"
                                                        @update:checked="record.usuarioRevision = !record.usuarioRevision; validarTipoUsuarioEdit('revision',record.usuarioRevision, record)"
                                                    />
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </span>

                                    <span slot="sueldoEmp"
                                        slot-scope="record">
                                        <div v-show="!record.editar">
                                            <CIcon :style="record.sueldoEmpresarial?'color:green':'color:red'" :name="record.sueldoEmpresarial?'cil-check-circle':'cil-x-circle'"/>
                                        </div>
                                        <div v-show="record.editar">
                                            <CSwitch
                                                width="150px"
                                                class="mx-1 align-middle mb-2" 
                                                color="success" 
                                                shape="pill"
                                                variant="outline"
                                                v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                :checked="record.sueldoEmpresarial"
                                                @update:checked="record.sueldoEmpresarial = !record.sueldoEmpresarial"
                                            />
                                        </div>

                                    </span>
                                    <span slot="resetearContraseña" slot-scope="record">
                                        <CButton v-show="!record.editar" type="submit" size="sm"
                                            :disabled="record.isReseteandoPsw"
                                            color="success" 
                                            class="btnPrincipalVapp float-left" 
                                            @click="resetearContraseña(record)">
                                                Resetear Contraseña
                                            <CIcon v-show="record.isReseteandoPsw" :content="$options.freeSet.cilLoopCircular" class="imgr" /> 
                                        </CButton>
                                    </span>

                                    <span slot="acciones"
                                        slot-scope="record">
                                            <CButtonToolbar >
                                                <CAlert v-show="record.eliminar" color="danger">
                                                    ¿Está seguro?
                                                    <CButtonGroup class="mx-1">
                                                        <CButton color="success" size="sm"
                                                            v-c-tooltip.hover="{content:'Si, Eliminar', placement:'left'}"
                                                            @click="eliminarUser(record)">
                                                            <CIcon name="cil-check-circle"/><!-- Si!-->
                                                        </CButton>
                                                        <CButton color="danger" size="sm"
                                                            v-c-tooltip.hover="{content:'No!', placement:'right'}"
                                                            @click="record.eliminar = false">
                                                            <CIcon name="cil-x-circle"/><!-- No!-->
                                                        </CButton>
                                                    </CButtonGroup>
                                                </CAlert>
                                                

                                                <CButtonGroup class="mx-1" v-show="record.editar && !record.eliminar">
                                                    <CButton color="success" size="sm"
                                                        v-c-tooltip.hover="{content:'Aceptar Cambios', placement:'left'}"
                                                        :disabled="!record.rutValidadoUser 
                                                        || !record.mailValidoUser 
                                                        || !record.userName.length>0
                                                        || !record.nombreUsuario.length>0
                                                        || !record.apellidoUsuario.length>0"
                                                        @click="editarUsuarios(record)">
                                                        <CIcon name="cil-check-circle"/><!-- Aceptar-->
                                                    </CButton>
                                                    <CButton color="danger" size="sm"
                                                        v-c-tooltip.hover="{content:'Cancelar Cambios', placement:'right'}"
                                                        @click="record.editar=false; cancelarEdicion(record)">
                                                        <CIcon name="cil-x-circle"/><!-- Cancelar-->
                                                    </CButton>
                                                </CButtonGroup>

                                                <CButtonGroup size="sm" class="mx-1" v-show="!record.editar && !record.eliminar">
                                                    <CButton color="warning" size="sm"
                                                    v-c-tooltip.hover="{content:'Editar Usuario', placement:'left'}"
                                                    @click="record.editar = true; editarUser(record)">
                                                        <CIcon name="cil-pencil"/><!-- Editar-->
                                                    </CButton>
                                                    <CButton color="danger" 
                                                    v-c-tooltip.hover="{content:'Eliminar Usuario', placement:'right'}"
                                                    @click="record.eliminar = true" size="sm" ><CIcon name="cil-x-circle"/><!-- Eliminar-->
                                                    </CButton>
                                                </CButtonGroup>
                                                
                                            </CButtonToolbar>
                                    </span>

                                    <span slot="asignar" slot-scope="record">
                                            <CButton type="submit" size="sm" 
                                                v-show="!record.usuarioAdmin && !record.editar"
                                                color="success" 
                                                class="btnPrincipalVapp float-left" 
                                                @click="asignarEmpresas(record)">
                                                Asignar Empresas
                                            </CButton>

                                        <div v-show="record.editar">
                                            <CRow>
                                                <CCol sm="5">
                                                    Admin
                                                </CCol>
                                                <CCol sm="3">
                                                    <CSwitch
                                                        width="150px"
                                                        class="mx-1 align-middle mb-2" 
                                                        color="success" 
                                                        shape="pill"
                                                        variant="outline"
                                                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                        :checked="record.usuarioAdmin"
                                                        @update:checked="record.usuarioAdmin = !record.usuarioAdmin; validarTipoUsuarioEdit('admin',record.usuarioAdmin,record)"
                                                    />
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="5">
                                                    Normal
                                                </CCol>
                                                <CCol sm="3">
                                                    <CSwitch
                                                        width="150px"
                                                        class="mx-1 align-middle mb-2" 
                                                        color="success" 
                                                        shape="pill"
                                                        variant="outline"
                                                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                        :checked="record.usuarioNormal"
                                                        @update:checked="record.usuarioNormal = !record.usuarioNormal; validarTipoUsuarioEdit('normal',record.usuarioNormal,record)"
                                                    />
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="5">
                                                    Revision
                                                </CCol>
                                                <CCol sm="3">
                                                    <CSwitch
                                                        width="150px"
                                                        class="mx-1 align-middle mb-2" 
                                                        color="success" 
                                                        shape="pill"
                                                        variant="outline"
                                                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                        :checked="record.usuarioRevision"
                                                        @update:checked="record.usuarioRevision = !record.usuarioRevision; validarTipoUsuarioEdit('revision',record.usuarioRevision, record)"
                                                    />
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </span>

                                    <!--<div slot="expandedRowRender" slot-scope="usuario">
                                        <CRow class="mb-2">
                                            <CCol md="5">
                                                <multiselect class="m-0 d-inline-block" 
                                                    v-model="tipoBDSelected"
                                                    deselect-label="" 
                                                    label="nombre" 
                                                    placeholder="Base de Datos" 
                                                    select-label=""
                                                    selectedLabel=""
                                                    :options="tipoBD" 
                                                    :searchable="false"
                                                    :loading="isLoadingTipoBD"
                                                    @select="getEmpresasPerfil(tipoBDSelected,usuario)"
                                                    >
                                                </multiselect>
                                            </CCol>
                                        </CRow>
                                        <CRow class="mb-2">
                                            <CCol md="12">
                                                <a-table
                                                    :columns="columnsEmpresas" 
                                                    :data-source="empresas"
                                                    :pagination="{ pageSize: 30 }"
                                                    :scroll="{ y: 300 }"
                                                    :locale="locale"
                                                    :loading="isLoadingEmpresas"
                                                    class="components-table-demo-nested m-1"
                                                    size="small"
                                                    style="overflow:auto;">

                                                    <CRow slot="nombreEmpresaTitle">
                                                        Nombre Empresa
                                                    </CRow>

                                                    <CRow slot="nombreEmpresa" slot-scope="empresa">
                                                        <CCol md="12">
                                                            ({{empresa.codEmpresa}}) {{empresa.nombreEmpresa}}
                                                        </CCol>
                                                    </CRow>

                                                    <CRow slot="asignado" slot-scope="empresa">
                                                        <CCol md="12">
                                                            <CSwitch
                                                                width="150px"
                                                                class="mx-1 align-middle" 
                                                                size="sm"
                                                                color="success" 
                                                                shape="pill"
                                                                variant="outline"
                                                                v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                                
                                                                :checked="empresa.perfilEmp"
                                                                @update:checked="empresa.perfilEmp = !empresa.perfilEmp; cambiarEstadoEmpresa(empresa)"
                                                            />
                                                        </CCol>
                                                    </CRow>

                                                </a-table>
                                            </CCol>
                                        </CRow>
                                    </div>-->
                                </a-table>
                            </CCol>
                        </CRow>
                    </CCardBody>
                    <CCardFooter>
                        
                        
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
        
        <!--@after-open="obtenerSoloInfoEmpresas"-->
        <Modal :enableClose="true" 
            v-model="asignarEmpresa"
            :modal-style="{'min-width': '300px', 'max-width': '1140px'}"
            @close="cerrarModalAsignar"
        >
            <div class="vm-content">
                <CRow>
                    <CCol sm="5">
                        <multiselect class="m-0 d-inline-block" 
                            v-model="tipoBDSelected"
                            deselect-label="" 
                            label="nombre" 
                            placeholder="Base de Datos" 
                            select-label=""
                            selectedLabel=""
                            :options="tipoBD" 
                            :searchable="false"
                            :loading="isLoadingTipoBD"
                            @input="getEmpresasPerfil"
                            >
                        </multiselect>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol md="5">
                        <multiselect
                            class="mt-2 mb-2"
                            :custom-label="customLabelEmp"
                            v-model="empSelected"
                            selectedLabel="Seleccionado"
                            deselectLabel="Quitar"
                            selectLabel="Agregar"
                            placeholder="Buscar Empresas" 
                            label="nombreEmpresa" 
                            track-by="codEmpresa"
                            :loading="isLoadingEmpresasInfo"
                            :options="empresasInfo" 
                            :multiple="true"
                            @select="filtrarEmpr"
                            @remove="rmvEmpSel">
                            <span slot="noOptions">Seleccione un tipo de Base de datos</span>
                        </multiselect>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol sm="12">
                        
                        <a-table
                            :columns="columnsEmpresas"
                            :data-source="empresas"
                            :pagination="{ pageSize: 30 }"
                            :scroll="{ y: 300 }"
                            :locale="locale"
                            :loading="isLoadingEmpresas"
                            class="components-table-demo-nested m-1"
                            size="small">

                            <CRow slot="nombreEmpresaTitle">
                                <CCol md="12">
                                    Nombre Empresa
                                </CCol>
                            </CRow>

                            <CRow slot="nombreEmpresa" slot-scope="empresa">
                                <CCol md="12">
                                    ({{empresa.codEmpresa}}) {{empresa.nombreEmpresa}}
                                </CCol>
                            </CRow>

                            <CRow slot="asignado" slot-scope="empresa">
                                <CCol md="12">
                                    <CSwitch
                                        width="150px"
                                        class="mx-1 align-middle" 
                                        size="sm"
                                        shape="pill"
                                        variant="outline"
                                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                        :checked="empresa.perfilEmp"
                                        @update:checked="agregarEmpresa(empresa)"
                                    />
                                </CCol>
                            </CRow>

                        </a-table>
                    </CCol>
                </CRow>
            </div>
            
            <div class="vm-footer">
                <CButton color="success" size="sm"
                    class="btnPrincipalVapp"
                    @click="guardarCambios">
                    Guardar
                </CButton>
            </div>

        </Modal>
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    
    import cons from '@/const'
    import Multiselect from 'vue-multiselect'
    import axios from "axios"
    import CrearUsuario from "./componentes/CrearUsuario"
    import { freeSet } from '@coreui/icons'

    import { MultiListSelect } from 'vue-search-select'

    import VueModal from '@kouts/vue-modal';
    import '@kouts/vue-modal/dist/vue-modal.css';
    import CargandoInfo from '@/views/utils/CargandoInfo'
    
    export default {
        name: 'AdministracionUsuarios',
        freeSet,
        components: {
            Multiselect,
            CrearUsuario,
            MultiListSelect,
            'Modal': VueModal,
            CargandoInfo
        },
        data () {
            return {
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,

                regMail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
                

                locale: {
                    filterConfirm: 'Filtrar',
                    filterReset: 'Limpiar',
                    emptyText: 'Seleccione sus opciones'
                },

                isLoadingUsuarios: false,
                isEditanandoUsuario: false,
                isEliminandoUsuario: false,
                isLoadingTipoBD: false,
                isLoadingEmpresas: false,
                isLoadingUpdtPerfEmpresas: false,

                nuevoUsuario: false,

                textoSinEmpresas: [],

                tipoBDSelected: null,

                asignarEmpresa: false,
                usuarioAsignar: null,

                usuarios: [],
                userEditados: [],
                tipoBD: [],
                empresas: [],
                empresasAgregadas: [],

                busquedaUsuario: "",
                
                page: 1,
                max: 100,




                columns: [
                    { key: 'rutUsuario', title: 'Rut', scopedSlots: { customRender: 'rutUsuario' }, align: 'left', width: '10%'},
                    { key: 'userName', title: 'Nombre Usuario (Tipo)', scopedSlots: { customRender: 'userName' }, align: 'left', width: '15%'},
                    { key: 'nombreUsuario', title: 'Nombres / Apellidos', scopedSlots: { customRender: 'nombreUsuario' }, align: 'left', width: '15%'},
                    { key: 'mailUsuario', title: 'Correo', scopedSlots: { customRender: 'mailUsuario' }, align: 'left', width: '20%', ellipsis: true},
                    { key: 'habilitado', title: 'habilitados', scopedSlots: { customRender: 'habilitado' }, align: 'center', width: '5%', ellipsis: false},
                    { title: 'Asignar', scopedSlots: { customRender: 'asignar' }, align: 'center', width: '10%', ellipsis: false},
                    { title: 'Reseteo Contraseña', scopedSlots: { customRender: 'resetearContraseña' }, align: 'center', width: '10%', ellipsis: false},
                    { title: 'Ver Sueldo Empresarial', scopedSlots: { customRender: 'sueldoEmp' }, align: 'center', width: '10%'},
                    { title: 'Acciones', scopedSlots: { customRender: 'acciones' }, align: 'left', width: '15%'}
                ],
                columnsEmpresas: [
                    { slots: { title: 'nombreEmpresaTitle' }, key: 'nombreEmpresa', scopedSlots: { customRender: 'nombreEmpresa' }, width: '40%', ellipsis: true, onFilter: (value, record)=>record.idEmpresa == value},
                    { title: 'Rut Empresa', dataIndex: 'rutEmpresa', key: 'rutEmpresa', width: '40%', ellipsis: true, onFilter: (value, record)=>record.rutEmpresa == value},
                    { title: 'Agregar', width: '40%', scopedSlots: { customRender: 'asignado' }, ellipsis: true}
                    
                ],
                
                isLoadingEmpresasInfo: false,
                empresasInfo: [],
                textoSinEmpresasInfo: "",
                emFlt: [],
                empSelected: [],
            }
        },
        created: function(){},
        beforeMount() {
            let cookieUsername = this.$cookie.get('userLogginToken')
            if(cookieUsername === null || cookieUsername === ""){
                this.$router.push("/pages/login");
            }
            this.getUsuarios()
            
            this.getTipoBds()
        },
        methods: {
            resetearContraseña: function(user){
                user.isReseteandoPsw = true

                var url = cons.port+"://" + cons.ipServer + "/resetear_psw"
                
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idUsuario": user.idUsuario
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {

                    
                    user.isReseteandoPsw = false
                    this.$notification.success(result.data.mensaje, {  timer: 10, position:"bottomRight"});

                }, error => {
                    //this.textoSinUsuarios = `Problema al obtener empresas , ${error}`
                    this.$notification.error(error.response.data.mensaje, {  timer: 10, position:"bottomRight"});
                    user.isReseteandoPsw = false
                });

            },
            customLabelEmp (empresa) {
                return `(${empresa.codEmpresa}) ${empresa.nombreEmpresa}`;
            },
            rmvEmpSel: function(emp){
                for(var indx=0;indx<=this.emFlt.length;indx++){
                    if(this.emFlt[indx].idEmpresa == emp.idEmpresa){
                        this.emFlt.splice(indx, 1)
                        break;
                    }
                }

                if(this.tipoBDSelected != null)
                    this.getEmpresasPerfil(this.tipoBDSelected)
                
            },
            filtrarEmpr: function(emp){
                this.emFlt.push(emp)
                if(this.tipoBDSelected != null)
                    this.getEmpresasPerfil(this.tipoBDSelected)
            },
            obtenerSoloInfoEmpresas: function(){
                this.isLoadingEmpresasInfo = true
                var url = cons.port+"://" + cons.ipServer + "/get_empresas_info/"+this.tipoBDSelected.valor;
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.empresasInfo = result.data;

                }, error => {
                    let mensaje = `Problema al obtener empresas , ${error}`
                    this.textoSinEmpresasInfo = mensaje
                })
                .finally(() => {
                    this.isLoadingEmpresasInfo = false
                });
            },
            getUsuarios: function(){
                this.isLoadingUsuarios = true

                var url = cons.port+"://" + cons.ipServer + "/get_usuarios_admin/"
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.usuarios = result.data;
                    
                    if(this.usuarios.length == 0)
                        this.locale.emptyText = "Sin usuarios creados"
                }, error => {
                    //this.textoSinUsuarios = `Problema al obtener empresas , ${error}`
                })
                .finally(() => {
                    this.isLoadingUsuarios = false
                });
            },
            aceptarEdicion: function(user){
                var encontrado = this.userEditados.find(userf => userf.idUsuario == user.idUsuario)

                if(!encontrado)
                    this.userEditados.push(user)

                user.editar = false


            },
            editarUser: function(user){

                user.respaldoUser = {
                    "idUsuario": user.idUsuario,
                    "userName": user.userName,
                    "rutUsuario": user.rutUsuario,
                    "nombreUsuario": user.nombreUsuario,
                    "apellidoUsuario": user.apellidoUsuario,
                    "habilitado": user.habilitado,
                    "idCliente": user.idCliente,
                    "usuarioAdmin": user.usuarioAdmin,
                    "usuarioRevision": user.usuarioRevision,
                    "usuarioNormal": user.usuarioNormal,
                    "mailUsuario": user.mailUsuario,
                    "sueldoEmpresarial": user.sueldoEmpresarial,
                    "editar": false
                }
            },
            editarUsuarios: function(user){
                this.isEditanandoUsuario = true
                
                var encontrado = this.userEditados.find(userf => userf.idUsuario == user.idUsuario)

                if(!encontrado)
                    this.userEditados.push(user)

                user.editar = false


                var url = cons.port+"://" + cons.ipServer + "/modificar_usuarios/"
                
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {"editados": this.userEditados},
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.estadoMensajeLista = "success"
                    this.mensajeListaUsuarios = result.data
                    this.monstrarMensajeListaUsuarios = true
                    this.getUsuarios()
                    this.userEditados = []
                    
                    this.$notification.success(result.data, {  timer: 10, position:"bottomRight"});

                }, error => {
                    this.mensajeListaUsuarios = `Problema al editar usuarios , ${error}`
                    this.estadoMensajeLista = "danger"
                    this.monstrarMensajeListaUsuarios = true

                    this.$notification.success(error.response.data, {  timer: 10, position:"bottomRight"});
                })
                .finally(() => {
                    this.isEditanandoUsuario = false
                });


            },
            editarUsuariosResp: function(){
                this.isEditanandoUsuario = true

                var url = cons.port+"://" + cons.ipServer + "/modificar_usuarios/"
                
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {"editados":this.userEditados},
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.estadoMensajeLista = "success"
                    this.mensajeListaUsuarios = result.data
                    this.monstrarMensajeListaUsuarios = true
                    this.getUsuarios()
                    this.userEditados = []
                    
                    this.$notification.success(result.data, {  timer: 10, position:"bottomRight"});

                }, error => {
                    this.mensajeListaUsuarios = `Problema al editar usuarios , ${error}`
                    this.estadoMensajeLista = "danger"
                    this.monstrarMensajeListaUsuarios = true

                    this.$notification.success(error.response.data, {  timer: 10, position:"bottomRight"});
                })
                .finally(() => {
                    this.isEditanandoUsuario = false
                });


            },
            eliminarUser: function(user){
                this.isEliminandoUsuario = true


                var url = cons.port+"://" + cons.ipServer + "/eliminar_usuario/"+user.idUsuario
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.getUsuarios()
                    
                    this.estadoMensajeLista = "success"
                    this.mensajeListaUsuarios = result.data
                    this.monstrarMensajeListaUsuarios = true
                    
                    var encontrado = this.userEditados.indexOf(this.userEditados.find(userf => userf.idUsuario == user.idUsuario))
                    if ( encontrado !== -1 ) {
                        this.userEditados.splice(encontrado, 1)
                    }
                    
                    this.$notification.success(result.data, {  timer: 10, position:"bottomRight"});

                }, error => {
                    this.mensajeListaUsuarios = `Problema al eliminar usuario , ${error}`
                    this.estadoMensajeLista = "danger"
                    this.monstrarMensajeListaUsuarios = true
                    
                    this.$notification.error(error.response.data, {  timer: 10, position:"bottomRight"});
                })
                .finally(() => {
                    this.isEliminandoUsuario = false
                });

            },
            cancelarEdicion: function(user){

                user.userName =  user.respaldoUser.userName
                user.rutUsuario = user.respaldoUser.rutUsuario
                user.nombreUsuario = user.respaldoUser.nombreUsuario
                user.apellidoUsuario = user.respaldoUser.apellidoUsuario
                user.habilitado = user.respaldoUser.habilitado
                user.idCliente = user.respaldoUser.idCliente
                user.usuarioAdmin = user.respaldoUser.usuarioAdmin
                user.usuarioRevision = user.respaldoUser.usuarioRevision
                user.usuarioNormal = user.respaldoUser.usuarioNormal
                user.mailUsuario = user.respaldoUser.mailUsuario
                user.sueldoEmpresarial = user.respaldoUser.sueldoEmpresarial
                user.respaldoUser = {}

                var encontrado = this.userEditados.indexOf(this.userEditados.find(userf => userf.idUsuario == user.idUsuario))
                if ( encontrado !== -1 ) {
                    this.userEditados.splice(encontrado, 1)
                }

            },
            validarTipoUsuarioEdit: function(tipoUsuario,valor,usuario){
                if(tipoUsuario == "normal"){
                    if(valor){
                        usuario.usuarioAdmin = false
                        usuario.usuarioRevision = false
                    }
                }
                if(tipoUsuario == "admin"){
                     if(valor){
                        usuario.usuarioNormal = false
                        usuario.usuarioRevision = false
                     }
                }
                if(tipoUsuario == "revision"){
                    if(valor){
                        usuario.usuarioAdmin = false
                        usuario.usuarioNormal = false
                    }
                }
            },
            getTipoBds: function(){
                this.isLoadingTipoBD = true

                var url = cons.port+"://" + cons.ipServer + "/get_tiposbds/"
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {

                    this.tipoBD = result.data;

                }, error => {
                    let mensaje = `Problema al obtener perfiles , ${error}`
                })
                .finally(() => {
                    this.isLoadingTipoBD = false
                });
            },

            asignarEmpresas: function(usuario){
                
                this.empresas = []
                this.tipoBDSelected = null

                this.asignarEmpresa = true
                this.usuarioAsignar = usuario
            },

            getEmpresasPerfil: function(bdSelected){
                this.empresasInfo = []
                this.empSelected = []
                this.emFlt = []
                this.empresas = []

                if(this.tipoBDSelected == null) return
                    

                console.log(bdSelected, this.tipoBDSelected)
                this.isLoadingEmpresas = true
                this.textoSinEmpresas = []
                //var idPerfil = perfil.idPerfil
                var bdSel = bdSelected.valor
                var idPerfil = this.usuarioAsignar.idPerfil==null ? 0 : this.usuarioAsignar.idPerfil
                var url = cons.port+"://" + cons.ipServer + "/get_empresas_perfil/"+ idPerfil +"/"+bdSel;

                
                if(this.tipoBDSelected != null && this.tipoBDSelected.valor != bdSelected.valor){
                    this.empresasAgregadas = []
                }
                
                this.obtenerSoloInfoEmpresas()
                
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info":{
                            "empSelecteds": this.emFlt
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.empresas = result.data;

                    if(result.data.length <= 0){
                        this.textoSinEmpresas = "No hay empresas"
                    }
                    this.validarAgregadas()
                }, error => {
                    let mensaje = `Problema al obtener empresas , ${error}`
                    this.textoSinEmpresas = mensaje
                })
                .finally(() => {
                    this.isLoadingEmpresas = false
                });
            },
            validarAgregadas: function(){
                this.empresas.forEach(emp => {
                    var encontrado = this.empresasAgregadas.find(empf => empf.idEmpresa == emp.idEmpresa)
                    
                    if(encontrado != undefined){
                        emp.perfilEmp = true
                    }
                });
            },

            agregarEmpresa: function(empresa){
                empresa.perfilEmp = !empresa.perfilEmp; 
                var encontrado = false

                this.empresasAgregadas.forEach(emp => {
                    if(emp.idEmpresa == empresa.idEmpresa){
                        emp.estado = empresa.perfilEmp
                        encontrado = true
                    }
                });
                if(!encontrado){
                    this.empresasAgregadas.push({
                        "idEmpresa": empresa.idEmpresa,
                        "codEmpresa": empresa.codEmpresa,
                        "estado": empresa.perfilEmp
                    })
                }
            },
            cerrarModalAsignar: function(){
                this.empresasAgregadas = []
            },
            guardarCambios: function(){
                
                this.isLoadingUpdtPerfEmpresas = true
                var bdSel = this.tipoBDSelected.valor
                var idPerfil = this.usuarioAsignar.idPerfil==null ? 0 : this.usuarioAsignar.idPerfil

                var url = cons.port + "://" + cons.ipServer + "/set_empresas_perfil/" + idPerfil + "/"+bdSel+"/"+JSON.stringify(this.empresasAgregadas)
                
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {"agregadas": this.empresasAgregadas},
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.empresasAgregadas = []
                    this.getEmpresasPerfil(this.tipoBDSelected)
                    this.$notification.success(result.data, {  timer: 10, position:"bottomRight"});

                }, error => {
                    let mensaje = `Problema al actualizar perfiles, ${error}`
                    
                    this.$notification.error(error.response.data, {  timer: 10, position:"bottomRight"});
                })
                .finally(() => {
                    this.isLoadingUpdtPerfEmpresas = false
                });
            },

            validarMail: function(user){
                if(user.mailUsuario == "" || user.mailUsuario == null){
                    user.mailValidoUser = false
                } else{
                    user.mailValidoUser =  this.regMail.test(user.mailUsuario)
                }

            },

            validarMaximo: function(evt, texto, campo) {
                var limite = 10
                switch(campo){
                    case "userName":
                        limite = 20;
                        break;
                    case "nombre":
                        limite = 30
                        break;
                    case "apellido":
                        limite = 30
                        break;
                    case "mail":
                        limite = 50
                        break;
                }

                if (texto.length >= limite) {
                    if (evt.keyCode >= 48 && evt.keyCode <= 90) {
                        evt.preventDefault();
                        return;
                    }
                }
            },

            validarRut: function(rutCompleto,user){
                
                if(rutCompleto == "" || rutCompleto == null){
                    user.rutValidadoUser = false
                } else{

                    rutCompleto = rutCompleto.replace("‐","-");

                    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
                        return false;

                    var tmp = rutCompleto.split('-');
                    var digv = tmp[1]; 
                    var rut = tmp[0];

                    if ( digv == 'K' ) digv = 'k' ;

                    user.rutValidadoUser = (this.validarDv(rut) == digv )
                }
                
            },
            validarDv: function(T){
                var M=0,S=1;
                for(;T;T=Math.floor(T/10))
                    S=(S+T%10*(9-M++%6))%11;
                return S?S-1:'k';
            },
            
        }
        
    }
</script>
<style scoped>

    .imgr{
        -webkit-animation: 3s rotate linear infinite;
        animation: 3s rotate  linear infinite;
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
</style>
